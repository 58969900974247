
  import { Component, Vue } from "vue-property-decorator";
  import LzEditorInput from "@/components/EditorInput.vue";
  import LzButton from "@/components/Button.vue";
  import { namespace } from "vuex-class";
  import Article from "../api";
  import LzConfirm from "@/components/Confirm.vue";
  const auth = namespace("auth");

  @Component({
    components: {
      LzEditorInput,
      LzButton,
      LzConfirm
    }
  })
  export default class BlogCreate extends Vue {
    @auth.State("id")
    memberId!: string;

    articleId = "";
    loaded = false;
    deleteModal = false;

    form = {
      title: "",
      icon: "" as string | { url: string }[],
      description: "",
      status: "enabled" as "enabled" | "disabled"
    };

    statusOptions = {
      enabled: this.$t("blog.read.table.item.status.enabled"),
      disabled: this.$t("blog.read.table.item.status.disabled")
    };

    onCancel() {
      this.$router.push({ name: "blogRead" });
    }

    async onSave(formData: ArticleSubmit) {
      const {
        title,
        icon: [{ url }]
      } = formData;
      const body: ArticlePostDTO = {
        title,
        // TODO: add status to form
        status: this.form.status,
        member_id: this.memberId,
        icon: url,
        description: this.form.description
      };
      try {
        if (this.articleId) {
          await Article.update(this.articleId, body);
        } else {
          await Article.create(body);
        }
        this.$notify({
          type: "success",
          text: this.$tc("blog.create.notifications.created")
        });
        this.$router.push({ name: "blogRead" });
      } catch {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
    }

    openDeleteModal() {
      this.deleteModal = true;
    }

    deleteArticle() {
      if (!this.articleId) return;

      Article.delete(this.articleId)
        .then(() => {
          this.$notify({
            type: "success",
            text: this.$tc("blog.create.notifications.deleted")
          });
          this.$router.push({ name: "blogRead" });
        })
        .catch(() => {
          this.$notify({
            type: "error",
            text: this.$tc("common.error.generic")
          });
        });
    }

    async mounted() {
      this.articleId = this.$route.params.articleId;
      if (this.articleId) {
        await Article.getById(this.articleId).then(article => {
          this.form = article;
          this.form.icon = [{ url: article.icon }];
        });
      }
      this.loaded = true;
    }
  }
